<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.926" x2="0.5" y1="-0.185" y2="0.661">
     <stop offset="0" stop-color="#d177f2"/>
     <stop offset="1" stop-color="#887eff"/>
    </linearGradient>
   </defs>
   <g id="Discord" transform="translate(-450 -528)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(450 528)" width="60"/>
    <path d="M19.9,16.286a1.755,1.755,0,1,1-1.749-1.9A1.826,1.826,0,0,1,19.9,16.286Zm-8.006-1.9a1.91,1.91,0,0,0,0,3.806,1.826,1.826,0,0,0,1.749-1.9A1.815,1.815,0,0,0,11.9,14.383ZM30,3.531V34.286c-4.319-3.817-2.938-2.553-7.954-7.217l.909,3.171H3.514A3.523,3.523,0,0,1,0,26.709V3.531A3.523,3.523,0,0,1,3.514,0H26.486A3.523,3.523,0,0,1,30,3.531ZM25.114,19.783a22.957,22.957,0,0,0-2.469-9.994,8.477,8.477,0,0,0-4.817-1.8l-.24.274a11.409,11.409,0,0,1,4.269,2.177,14.566,14.566,0,0,0-12.806-.5c-.634.291-1.011.5-1.011.5a11.565,11.565,0,0,1,4.509-2.246l-.171-.206a8.477,8.477,0,0,0-4.817,1.8,22.957,22.957,0,0,0-2.469,9.994,6.216,6.216,0,0,0,5.229,2.606s.634-.771,1.149-1.423a5.332,5.332,0,0,1-3-2.023c.252.177.668.405.7.429a12.5,12.5,0,0,0,10.7.6A9.81,9.81,0,0,0,21.84,18.96a5.409,5.409,0,0,1-3.1,2.04c.514.651,1.131,1.389,1.131,1.389A6.266,6.266,0,0,0,25.114,19.783Z" data-name="Icon awesome-discord" id="Icon_awesome-discord" style="fill: url(#linear-gradient);" transform="translate(465 541)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>